import { IonContent, IonPage, useIonToast } from '@ionic/react';
import { Typography } from '@mui/material';
import { goBack } from 'connected-react-router';
import { checkmarkCircleSharp, closeCircleSharp } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import inbox from '../../../assets/images/inbox.svg';
import settings from '../../../assets/images/settings.svg';
import subscriptions from '../../../assets/images/subscriptions.svg';
import transaction from '../../../assets/images/transaction.svg';
import verified from '../../../assets/images/verified.svg';
import { SET_USER_DATA, SPINNER_VISIBLE } from '../../../constants/actionTypes';
import cognitoService from '../../../core/service/cognito.service';
import fetchData from '../../../fetch';
import { getBase64 } from '../../../helpers/FileReader';
import { isColorSimilar } from '../../../utils';
import OnBack from '../../Auth/OnBack';
import StreamingStats from '../../General/StreamingStats';
import useImageConversion from '../../hooks/useImageConversion';
import styles from '../Customize/customize.module.scss';
import settingsStyles from '../settings.module.scss';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
        company: state.common.company,
        connected_account: state.common.connected_account
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload })
});

const ProfilePage = (props) => {
    const { t } = useTranslation();
    const { data: user } = props.user;
    const fullname = `${user?.given_name} ${user?.family_name}`;
    let backgroundColor = '#DBDDE0';
    const hiddenFileInput = useRef(null);
    const [changed, setChanged] = useState(false);
    const [photo, setPhoto] = useState(null);
    const [imagePreview, setImagePreview] = useState(props.user.imagePreview || '');
    const [present] = useIonToast();
    const presentToast = (message, icon, color, duration) => {
        present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            color: color
        });
    };
    const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';

    if (!user?.picture) {
        if (props.company !== null && props.company.primaryColor !== null) {
            const isSimilar = isColorSimilar(props.company.primaryColor, '#DBDDE0', 50);
            backgroundColor = isSimilar ? '#444444' : '#DBDDE0';
        }
    }

    let profilePicture;
    let images = '/images';
    let initials;

    if (user?.picture) {
        profilePicture = user.picture;
    } else {
        initials = user?.given_name?.charAt(0) + user?.family_name?.charAt(0);
    }

    const handleClick = async () => {
        hiddenFileInput.current.accept = 'image/*';
        hiddenFileInput.current.click();
    };

    const handleImageChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let currentFile = e.target.files[0];
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = currentFile.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            presentToast(
                'Please select a valid image file (jpg, jpeg, png).',
                closeCircleSharp,
                'danger',
                1500
            );
            return;
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        currentFile = await useImageConversion(currentFile);

        reader.onloadend = () => {
            setImagePreview(reader.result);
            setChanged(true);
        };
        // setPhoto(currentFile);
        reader.readAsDataURL(currentFile);
        if (e.target?.files.length > 0) {
            let reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                setChanged(true);
            };
            // setPhoto(currentFile);
            reader.readAsDataURL(currentFile);
        }
        try {
            if (currentFile) {
                props.onSpinner(true);
                const base64Image = await getBase64(currentFile);
                const image = base64Image.split(';base64,')[1];

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ image })
                };
                const { thumbnailUrl } = await fetchData.Image.Upload(requestOptions);

                await cognitoService.addPicture(thumbnailUrl);
                props.onSpinner(false);
            }
        } catch (e) {
            props.onSpinner(false);
            return;
        } finally {
            presentToast('Saved changes.', checkmarkCircleSharp, 'success', 1500);
        }

        const currentUser = await cognitoService.getUserData();
        props.onSetUserData(currentUser);
    };

    if (window.cordova) {
        images = 'images';
    }
    return (
        <IonPage>
            <OnBack defaultHref={'/events'} />
            <div className={styles.profileWrapper}>
                <IonContent>
                    <div className={styles.mobilePadding} style={{ padding: '0vh 2vw' }}>
                        <div style={{ marginBottom: '5vh' }}>
                            <div
                                className={settingsStyles.profilePhoto}
                                style={{ alignItems: 'flex-start' }}>
                                <div className={settingsStyles.profilePhotoEdit}>
                                    {!imagePreview && user.picture && (
                                        <img
                                            style={{
                                                width: 123,
                                                height: 120,
                                                borderRadius: '85px',
                                                cursor: 'pointer'
                                            }}
                                            src={profilePicture}
                                            alt={t('Avatar')}
                                            onClick={handleClick}
                                        />
                                    )}
                                    {!imagePreview && !user.picture && (
                                        <div
                                            style={{
                                                backgroundColor: backgroundColor,
                                                cursor: 'pointer'
                                            }}
                                            className={settingsStyles.profilePhotoGenerated}
                                            onClick={handleClick}>
                                            {initials}
                                        </div>
                                    )}
                                    {imagePreview && (
                                        <img
                                            style={{
                                                width: 123,
                                                height: 120,
                                                borderRadius: '85px',
                                                cursor: 'pointer'
                                            }}
                                            src={imagePreview}
                                            alt={t('Avatar')}
                                            onClick={handleClick}
                                        />
                                    )}
                                    <svg
                                        style={{ cursor: 'pointer' }}
                                        className={settingsStyles.edit}
                                        onClick={handleClick}
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20" cy="20" r="20" fill={'black'} />
                                        <path
                                            d="M17.5 26.8749H13.75C13.5842 26.8749 13.4253 26.8091 13.3081 26.6918C13.1908 26.5746 13.125 26.4157 13.125 26.2499V22.7588C13.125 22.6767 13.1412 22.5954 13.1726 22.5196C13.204 22.4438 13.25 22.3749 13.3081 22.3168L22.6831 12.9418C22.8003 12.8246 22.9592 12.7588 23.125 12.7588C23.2908 12.7588 23.4497 12.8246 23.5669 12.9418L27.0581 16.433C27.1753 16.5502 27.2411 16.7091 27.2411 16.8749C27.2411 17.0407 27.1753 17.1996 27.0581 17.3168L17.5 26.8749Z"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M20.625 15L25 19.375"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <defs>
                                            <linearGradient
                                                id="paint0_linear"
                                                x1="36"
                                                y1="-1.44541e-06"
                                                x2="3.5"
                                                y2="40"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#57B177" />
                                                <stop offset="1" stopColor="#367B4E" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={hiddenFileInput}
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                            <Typography margin={'2vh 0'} textAlign={'center'}>
                                {fullname}
                            </Typography>
                        </div>
                        {props.isAdmin && (
                            <div style={{ marginBottom: '3vh' }}>
                                <StreamingStats />
                            </div>
                        )}
                        <hr />
                        <div>
                            <div style={{ margin: '3vh 0' }}>
                                <Link
                                    to={
                                        props.isAdmin
                                            ? `/profile/manage-subscriptions`
                                            : `/profile/user-subscriptions`
                                    }
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                        <img src={verified} />
                                        <span style={{ marginLeft: '12px' }}>{t('Premium')}</span>
                                    </div>
                                    <img src={`${images}/right_arrow.svg`} alt="Transaction" />
                                </Link>
                            </div>
                            {props.isAdmin ? (
                                <>
                                    <div style={{ margin: '3vh 0' }}>
                                        <Link
                                            to={'/profile/myvideos'}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                <img src={subscriptions} />
                                                <span style={{ marginLeft: '12px' }}>
                                                    {t('MyVideos')}
                                                </span>
                                            </div>
                                            <img
                                                src={`${images}/right_arrow.svg`}
                                                alt="Right Arrow"
                                            />
                                        </Link>
                                    </div>
                                    <div style={{ margin: '3vh 0' }}>
                                        <Link
                                            to={
                                                premiumOnly
                                                    ? '/profile/branding'
                                                    : '/profile/customize'
                                            }
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                <img src={verified} />
                                                <span style={{ marginLeft: '12px' }}>
                                                    {premiumOnly ? t('Branding') : t('Customize')}
                                                </span>
                                            </div>
                                            <img
                                                src={`${images}/right_arrow.svg`}
                                                alt="Right Arrow"
                                            />
                                        </Link>
                                    </div>
                                    <div style={{ margin: '3vh 0' }}>
                                        <Link
                                            to={`/profile/balance`}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                <img src={transaction} />
                                                <span style={{ marginLeft: '12px' }}>
                                                    {t('BalanceAndTransactions')}
                                                </span>
                                            </div>
                                            <img
                                                src={`${images}/right_arrow.svg`}
                                                alt="Right Arrow"
                                            />
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <div style={{ margin: '3vh 0' }}>
                                    <Link
                                        to={`/profile/transactions`}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                            <img src={transaction} />
                                            <span style={{ marginLeft: '12px' }}>
                                                {t('TransactionHistory')}
                                            </span>
                                        </div>
                                        <img src={`${images}/right_arrow.svg`} alt="Right Arrow" />
                                    </Link>
                                </div>
                            )}
                            <div style={{ margin: '3vh 0' }}>
                                <Link
                                    to={`/profile/settings`}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                        <img src={settings} />
                                        <span style={{ marginLeft: '12px' }}>
                                            {t('AccountSettings')}
                                        </span>
                                    </div>
                                    <img src={`${images}/right_arrow.svg`} alt="Right Arrow" />
                                </Link>
                            </div>
                            {props.user?.premium_services?.subscription && props.isAdmin && (
                                <div style={{ margin: '3vh 0' }}>
                                    <Link
                                        to={`/profile/invoices`}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                            <img src={inbox} />
                                            <span style={{ marginLeft: '12px' }}>
                                                {t('Invoices')}
                                            </span>
                                        </div>
                                        <img src={`${images}/right_arrow.svg`} alt="Right Arrow" />
                                    </Link>
                                </div>
                            )}
                            {premiumOnly && (
                                <div style={{ margin: '3vh 0' }}>
                                    <Link
                                        to={`/profile/account`}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                            {!props.connected_account?.onboarded ? (
                                                <HandshakeIcon sx={{ color: '#888' }} />
                                            ) : (
                                                <AccountBalanceIcon sx={{ color: '#888' }} />
                                            )}
                                            <span style={{ marginLeft: '12px' }}>
                                                {!props.connected_account?.onboarded
                                                    ? t('OnboardMe')
                                                    : t('ManageBankAccount')}
                                            </span>
                                        </div>
                                        <img src={`${images}/right_arrow.svg`} alt="Right Arrow" />
                                    </Link>
                                </div>
                            )}
                        </div>
                        <Typography
                            className={styles.itemSubtitle}
                            paddingBottom={'7vh'}
                            textAlign={'center'}>
                            {t('PersonalDataNotShare')}{' '}
                            <Link to={'/privacyPolicy'}>
                                <span style={{ color: 'black', textDecoration: 'underline' }}>
                                    {t('TermsOfUse')}
                                </span>
                            </Link>
                        </Typography>
                    </div>
                </IonContent>
            </div>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
