import {
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonPage
} from '@ionic/react';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    CONVERTING_STATUS,
    CONVERT_FINISHED_STATUS,
    CONVERT_STATUS,
    NOT_PUBLISHED,
    UPLOADING_STATUS
} from '../../constants';
import {
    BLOG_LIST_PAGE_LOADED,
    EVENT_LIST_PAGE_LOADED,
    EVENT_PAGE_LOADED,
    EVENT_PAGE_UNLOADED,
    PEOPLE_WATCHING,
    PUSH_TOKEN_UPDATE,
    SET_EVENT_TAB_ACTIVE,
    SET_USER_DATA,
    UPLOAD_REMOVE
} from '../../constants/actionTypes';
import Event from '../../core/entitites/event.entity';
import cognitoService from '../../core/service/cognito.service';
import sanity from '../../sanity';
import { isUrlValid, transformToSanityUrl, updateMetaTags } from '../../utils';
import LoginNotification from '../Auth/LoginNotification';
import Nav from '../Nav/TopMenu';
import EventInfo from './EventInfo';
import EventListItem from './EventListItem';
import PublishingQueue from './PublishingQueue';
import styles from './eventList.module.scss';
import { Helmet } from 'react-helmet-async';

const mapStateToProps = (state) => ({
    events: state.event.events,
    roomId: state.event.roomId,
    isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
    currentlyWatching: state.event.currentlyWatching,
    user: state.user.currentUser,
    blogPosts: state.blog.blogPosts,
    appStateEventTabActive: state.appState.eventTabActive,
    company: state.common.company,
    upload: state.common.upload,
    pwaPromptFlag: state.common.pwaPromptFlag
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: (payload) => dispatch({ type: EVENT_LIST_PAGE_LOADED, payload }),
    updatePushToken: (payload) => dispatch({ type: PUSH_TOKEN_UPDATE, payload }),
    onLoadPinned: (payload) => {
        dispatch({ type: EVENT_PAGE_LOADED, payload });
    },
    onUnloadPinned: () => {
        dispatch({ type: EVENT_PAGE_UNLOADED });
    },
    liveViewers: (payload) => {
        dispatch({ type: PEOPLE_WATCHING, payload });
    },
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    setBlogPostsList: (payload) => dispatch({ type: BLOG_LIST_PAGE_LOADED, payload }),
    setEventTabActive: (payload) => dispatch({ type: SET_EVENT_TAB_ACTIVE, payload }),
    onRemoveUpload: (payload) => dispatch({ type: UPLOAD_REMOVE, payload })
});

function EventList(props) {
    const { t } = useTranslation();
    const history = useHistory();

    const [eventList, setEventList] = useState([]);
    const [pinnedEvent, setPinnedEvent] = useState(null);
    const [isLiveEvent, setIsLiveEvent] = useState(false);
    const [isClicked, setIsClicked] = useState(true);
    const [isNotifActive, setIsNotifActive] = useState(false);
    const [offset, setOffset] = useState(10);
    const [loadEvents, setLoadEvents] = useState([]);
    const [noMore, setNoMore] = useState(true);
    const [displayEvents, setDisplayEvents] = useState(null);
    const [publishingEvents, setPublishingEvents] = useState([]);
    const [finalList, setFinalList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);
    const [technicalError, setTechnicalError] = useState(false);
    const [videoDeleted, setVideoDeleted] = useState(false);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaImage, setMetaImage] = useState('');

    useEffect(() => {
        const loadEvents = async () => {
            let events = await sanity.Events.GetEvents(true);
            events = events
                .filter(
                    (event) =>
                        event.stream_status === 'archived' ||
                        (event.stream_status === 'upcoming' &&
                            event.processingStatus === CONVERT_FINISHED_STATUS) ||
                        event.stream_status === 'active'
                )
                .sort((e1, e2) => e2.startDate.getTime() - e1.startDate.getTime());
            setEventList(events);
            props.onLoad(events);
        };
        loadEvents();
        // decidePinnedEvent();
        if (window.cordova) {
            updatePushNotificationsToken();
        }
    }, [videoDeleted]);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            const blogs = await sanity.BlogPosts.GetAllBlogPosts();
            props.setBlogPostsList(blogs);
        };
        fetchBlogPosts();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            let events = await sanity.Events.GetEvents(props.isAdmin);
            const finishedEvents = events.filter(
                (event) =>
                    event.processingStatus === CONVERT_FINISHED_STATUS &&
                    props.upload.some((u) => u.jobId === event.correlation_id)
            );
            setFinalList(finishedEvents);

            const publishingEvents = events
                .filter(
                    (event) =>
                        event.processingStatus === CONVERTING_STATUS ||
                        event.processingStatus === UPLOADING_STATUS ||
                        event.processingStatus === CONVERT_STATUS
                )
                .map((e) => {
                    const runtimeProps = props.upload.find(
                        (uploadedEvent) => uploadedEvent.jobId === e.correlation_id
                    );
                    return runtimeProps ||
                        e.processingStatus === CONVERTING_STATUS ||
                        e.processingStatus === CONVERT_STATUS
                        ? Event.new({ ...e, ...runtimeProps })
                        : null;
                })
                .filter((e) => e !== null);
            const eventList = events.filter(
                (e) =>
                    !publishingEvents.some((p) => p.id === e.id) &&
                    e.stream_status !== NOT_PUBLISHED
            );
            setEventList(eventList);
            setPublishingEvents([...publishingEvents, ...finishedEvents]);
            if (finishedEvents.length > 0) {
                props.onRemoveUpload(finishedEvents);
            }
        };
        fetchData();
    }, [props.upload, props.events]);

    useEffect(() => {
        setTimeout(() => {
            setFinalList([]);
        }, 5000);
    }, [props.upload]);

    const decidePinnedEvent = async () => {
        const realEvents = props.events.filter((event) => event.stream_status !== 'unpublished');

        const liveEvent = realEvents
            .sort((e1, e2) => e2.startDate.getTime() - e1.startDate.getTime())
            .find((e) => e.stream_status === 'active');

        if (liveEvent && (await isUrlValid(liveEvent.live_stream_url))) {
            setPinnedEvent(liveEvent);
            setIsLiveEvent(true);
            setModalFlag(true);
            return;
        }
        const explicitlyPinned = realEvents.find((e) => e.pinned);
        if (explicitlyPinned) {
            setPinnedEvent(explicitlyPinned);
            return;
        }

        const sortedEvents = realEvents
            .filter((e) => new Date(e.start_date) <= new Date(newEventDate(new Date())))
            .sort((e1, e2) => e2.startDate.getTime() - e1.startDate.getTime());

        for (let i = 0; i < sortedEvents.length; i++) {
            if (sortedEvents[i]?.stream_type === 'webrtc_ivs') {
                setPinnedEvent(sortedEvents[i]);
                if (sortedEvents[i].stream_status === 'archived') {
                    setIsLiveEvent(false);
                    setTechnicalError(false);
                    if (modalFlag) {
                        setOpenModal(true);
                        setModalFlag(false);
                    }
                }
                if (technicalError) {
                    setOpenModal(true);
                }
                break;
            } else {
                if (
                    sortedEvents[i].stream_type === 'url_pull' &&
                    new Date(sortedEvents[i].start_date) < new Date() &&
                    sortedEvents[i].processingStatus === CONVERT_FINISHED_STATUS
                ) {
                    setPinnedEvent(sortedEvents[i]);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        if (props.events && props.events.length > 0) {
            decidePinnedEvent();
        }
        // if (technicalError) {
        //     if (pinnedEvent.stream_status === 'archived') {
        //         setTechnicalError(false);
        //         return;
        //     }
        //     setOpenModal(true);
        // }
    }, [props.events]);

    useEffect(() => {
        if (pinnedEvent) {
            if (pinnedEvent.stream_status === 'upcoming') {
                return;
            }
            if (pinnedEvent.thumbnail?.image_asset?.asset) {
                const image = sanity.urlFor(pinnedEvent.thumbnail.image_asset.asset);
                const transformedImage = transformToSanityUrl(image.options);
                setMetaImage(transformedImage);
            }
        }
    }, [pinnedEvent]);

    const updatePushNotificationsToken = () => {
        const cordovaDevice = device;
        if (cordovaDevice) {
            const pushType = cordovaDevice.platform
                ? cordovaDevice.platform === 'iOS'
                    ? 'APNS'
                    : 'GCM'
                : 'GCM';
            const id = props.user.data.email ? props.user.data.email : props.user.data.phone_number;
            window?.pushNotification?.registration &&
                window.pushNotification.registration(
                    (token) => {
                        props.updatePushToken({
                            id: device.uuid,
                            userId: id,
                            token: token,
                            pushType: pushType
                        });
                    },
                    (error) => {
                        console.error(error);
                    }
                );
        }
    };

    useEffect(() => {
        if (props.roomId) {
            props.liveViewers({ room_id: props.roomId });
            const i = setInterval(async () => {
                props.liveViewers({ room_id: props.roomId });
            }, 15000);
            return () => {
                props.onUnloadPinned();
                clearInterval(i);
            };
        }
    }, [props.roomId]);

    const newEventDate = (newEventD) => {
        const future = new Date(newEventD);
        return future.setMinutes(future.getMinutes());
        // used for preview scheduled events 15 minutes before scheduled time
        // return future.setMinutes(future.getMinutes() + 15);
    };

    const buttonClicked = (e, isOpen) => {
        e.preventDefault();
        setIsClicked(isOpen);
    };

    useEffect(() => {
        async function fetchData() {
            if (props.user.data != null) {
                const userData = await cognitoService.getUserData();
                if (userData.data['custom:log_counter'] === undefined) {
                    cognitoService.setLogNumber(1);
                    userData.data['custom:log_counter'] = parseInt('1');
                    props.onSetUserData(userData);
                    setIsNotifActive(true);
                } else {
                    const logNumber = parseInt(userData.data['custom:log_counter']);
                    if (logNumber < 10) {
                        cognitoService.setLogNumber(logNumber + 1);
                        userData.data['custom:log_counter'] = logNumber + 1;
                        props.onSetUserData(userData);
                        setIsNotifActive(false);
                    }
                }
            }
        }

        fetchData();
    }, []);

    const fetchMoreEvents = () => {
        // setTimeout(() => {
        if (eventList.length > offset) {
            let ev = eventList.slice(0, offset + 10);
            setLoadEvents(ev);
            setOffset(offset + 10);
        } else setNoMore(false);
        // }, 1500);
    };

    useEffect(() => {
        let ev = eventList.slice(0, 10).map((e) => {
            return e;
        });
        setLoadEvents(ev);
    }, [eventList]);

    useEffect(() => {
        if (props.company?.name) {
            setMetaTitle(props.company.name);
            setMetaDescription(t('AppDescription', { appname: `${props.company.name}` }));
        }
        async function fetchEvents() {
            if (props.company?.shown_events && props.company?.shown_events != null) {
                setDisplayEvents(props.company.shown_events);
            } else {
                const params = await sanity.GlobalParams.GetGlobalParams();
                setDisplayEvents(params.shown_events_global);
            }
        }

        fetchEvents();
    }, [props.company]);

    const handleCancel = (eventToRemove) => {
        props.onRemoveUpload([eventToRemove]);
        const updatedEvents = publishingEvents.filter((e) => e.id !== eventToRemove.id);
        setPublishingEvents(updatedEvents);
    };
    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription}></meta>
                <link rel="canonical" href="/events"></link>
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={metaImage} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={metaTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:image" content={metaImage} />
            </Helmet>
            <IonPage>
                <Nav renderCompany renderSearch renderProfile />

                <IonContent>
                    <div onClick={(e) => buttonClicked(e, false)}>
                        {isNotifActive && props.company && (
                            <LoginNotification
                                isClicked={isClicked}
                                setIsClicked={setIsClicked}
                                icon="👋🏻"
                                title={t('GetMostFromYourProfile', {
                                    company_name: `${props.company.name}`
                                })}
                            />
                        )}
                        {/* <Drawer anchor="bottom" open={props.pwaPromptFlag}>
                        <PWAPrompt />
                    </Drawer> */}
                        {/* {props.isAdmin &&
                        publishingEvents.map((e) => {
                            return (
                                <div key={e.id} className={styles.publishing}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <EventThumbnail event={e} />
                                        <div
                                            style={{
                                                flex: 1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                            <div>
                                                <Typography color={'#888'}>
                                                    {e.processingStatus === UPLOADING_STATUS
                                                        ? t('Uploading')
                                                        : e.processingStatus === CONVERT_STATUS
                                                        ? t('Queued')
                                                        : e.processingStatus === CONVERTING_STATUS
                                                        ? t('Publishing')
                                                        : e.processingStatus ===
                                                          CONVERT_FINISHED_STATUS
                                                        ? `${t('Done')}!`
                                                        : t('Unknown')}
                                                </Typography>
                                                <Typography>{e.title}</Typography>
                                            </div>
                                            {e.processingStatus === UPLOADING_STATUS && (
                                                <Button
                                                    onClick={() => handleCancel(e)}
                                                    sx={{
                                                        border: 'none',
                                                        color: 'white !important',
                                                        textTransform: 'none !important'
                                                    }}>
                                                    {t('Cancel')}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    <LinearProgress
                                        size={160}
                                        variant="determinate"
                                        value={e.processing_progress}
                                    />
                                </div>
                            );
                        })} */}
                        {publishingEvents.length > 0 && props.isAdmin && !pinnedEvent && (
                            <PublishingQueue
                                publishingEvents={publishingEvents}
                                handleCancel={handleCancel}
                            />
                        )}
                        {pinnedEvent && (
                            <EventInfo
                                event={pinnedEvent}
                                isLive={isLiveEvent}
                                currentlyWatching={props.currentlyWatching}
                                isPinned
                                setTechnicalError={setTechnicalError}
                                isEventList={true}
                                handleCancel={handleCancel}
                                publishingEvents={publishingEvents}
                                setVideoDeleted={setVideoDeleted}
                                isAdmin={props.isAdmin}
                            />
                        )}
                        <Modal open={openModal}>
                            <div className={isMobile ? styles.modalMobile : styles.modal}>
                                <Typography
                                    marginTop={isMobile ? '3%' : '-1%'}
                                    fontSize={'18px'}
                                    textAlign={'center'}>
                                    {technicalError ? t('TechnicalError') : t('LiveStreamEnded1')}
                                </Typography>
                                {!technicalError && (
                                    <Typography fontSize={'18px'} textAlign={'center'}>
                                        {t('LiveStreamEnded2')}
                                    </Typography>
                                )}
                                <div className={styles.flexContainer}>
                                    {!loading ? (
                                        <>
                                            <Button
                                                className={
                                                    isMobile
                                                        ? styles.confirmButtonMobile
                                                        : styles.confirmButton
                                                }
                                                onClick={() => window.location.reload()}>
                                                {t('Reload')}
                                            </Button>
                                            <Button
                                                className={
                                                    isMobile
                                                        ? styles.cancelButtonMobile
                                                        : styles.cancelButton
                                                }
                                                onClick={() => setOpenModal(false)}>
                                                {t('Close')}
                                            </Button>
                                        </>
                                    ) : (
                                        <div
                                            style={{
                                                margin: '0 auto'
                                            }}>
                                            <CircularProgress />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Modal>
                        <div className={styles.cardsContainer}>
                            <div id={'events'}>
                                {eventList.length >= 1 && props.user.data != null && (
                                    <>
                                        <IonList>
                                            {loadEvents
                                                .filter((x) => x?.id != pinnedEvent?.id)
                                                .map((teamEvent) => {
                                                    return (
                                                        <EventListItem
                                                            key={teamEvent.id}
                                                            event={teamEvent}
                                                            isAdmin={props.isAdmin}
                                                        />
                                                    );
                                                })}
                                        </IonList>
                                        <IonInfiniteScroll
                                            onIonInfinite={(ev) => {
                                                if (noMore) {
                                                    fetchMoreEvents();
                                                    ev.target.complete();
                                                } else {
                                                    ev.target.disabled = true;
                                                }
                                            }}>
                                            <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                        </IonInfiniteScroll>
                                    </>
                                )}

                                {eventList.length >= 1 && props.user.data == null && (
                                    <div>
                                        {loadEvents
                                            .filter((x) => x?.id != pinnedEvent?.id)
                                            .slice(0, displayEvents - 1)
                                            .map((teamEvent) => {
                                                return (
                                                    <EventListItem
                                                        key={teamEvent.id}
                                                        event={teamEvent}
                                                        isAdmin={props.isAdmin}
                                                    />
                                                );
                                            })}
                                        {props.company && (
                                            <p style={{ textAlign: 'center' }}>
                                                {t('SignUpToSeeMoreEvents', {
                                                    company_name: `${props.company.name}`
                                                })}
                                                <br />
                                                <button
                                                    onClick={() => history.push('/register')}
                                                    style={{ marginTop: '10px' }}>
                                                    {t('Register')}
                                                </button>
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {props.isAdmin && (
                        <Box position="fixed" bottom={20} right={20}>
                            <Fab
                                aria-label="add"
                                color="primary"
                                onClick={() => {
                                    history.push('/create-video');
                                }}>
                                <VideoCallIcon />
                            </Fab>
                        </Box>
                    )}
                </IonContent>
            </IonPage>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
