import { useIonToast } from '@ionic/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    Box,
    Button,
    Drawer,
    FormControlLabel,
    IconButton,
    Switch,
    Typography
} from '@mui/material';
import { checkmarkCircleSharp, closeCircleSharp } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
    GET_WATCH_COUNT,
    RESET_WATCH_COUNT,
    SET_USER_DATA,
    SET_WIDGET_PAST_EVENT
} from '../../constants/actionTypes';
import sanity from '../../sanity';
import { canSeeEvent, isUrlValid } from '../../utils.js';
import Player from '../Player/Player';
import CountdownPlayer from './CountdownPlayer';
import EventOptions from './EventOptions';
import EventSummary from './EventSummary';
import PremiumContent from './PremiumContent';
import styles from './eventInfo.module.scss';
import IosShareIcon from '@mui/icons-material/IosShare';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import stylesChat from '../Chat/chatContainer.module.scss';
import { makeStyles } from '@mui/styles';
import PublishingQueue from './PublishingQueue.js';
import PremiumContentV2 from './PremiumContentV2.js';

const useStyles = makeStyles(() => ({
    switch: {
        '& .MuiSwitch-track': {
            backgroundColor: 'lightgray'
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: 'white'
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#32E7B2'
        }
    }
}));

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        company: state.common.company,
        views: state.event.views
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    getWatchCount: (payload) => dispatch({ type: GET_WATCH_COUNT, payload }),
    resetWatchCount: () => dispatch({ type: RESET_WATCH_COUNT })
});

const EventInfo = (props) => {
    const { t } = useTranslation();
    let isLive = props.isLive;
    const {
        eventOpen,
        isPinned,
        isEventPage,
        setShowOptions,
        views: { totalViews = 0 } = {}
    } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const uniqueHash = useMemo(() => uuidv4(), []);

    const [countDownTrigger, setCountDownTrigger] = useState(false);
    const [isStopped, setIsStopped] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isEventOpened, setIsEventOpened] = useState(false);
    const [liveLabel, setLiveLabel] = useState(false);
    const [live, setLive] = useState(props.isLive);
    const [isExternalStream, setIsExternalStream] = useState(false);
    const [isPortrait, setIsPortrait] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [toggled, setToggled] = useState(false);
    const classes = useStyles();
    const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';
    const [present] = useIonToast();
    const presentToast = (message, duration, color) => {
        present({
            message: message,
            duration: duration,
            position: 'top',
            icon: color === 'success' ? checkmarkCircleSharp : closeCircleSharp,
            color: 'success'
        });
    };

    const CONTROL_BAR_VIDEO_PLAYER_HEIGHT = 40; // default height = 30 + 10 as offset
    const NAVBAR_HEIGHT = 60;

    let eye = window.cordova ? 'images/eye.svg' : '/images/eye.svg';

    const videoTimeCallback = (secondsPassed, duration) => {
        let tenSecondsPassed = Math.floor(secondsPassed / 10);
        let tenSecondsDuration = Math.floor(duration / 10);

        let stickyWidgets = props.event.sticky_widgets;
        let nextWidget;

        if (stickyWidgets && stickyWidgets.length > 0) {
            const sortedWidgets = stickyWidgets.sort(
                (w1, w2) => new Date(w1.beginDelay - new Date(w2.beginDelay))
            );
            nextWidget = sortedWidgets.find((widget) => {
                let tenSecondsDelay = Math.floor(widget.beginDelay / 10);
                if (!widget.beforeEndSwitch && tenSecondsDelay === tenSecondsPassed) return widget;
                if (
                    widget.beforeEndSwitch &&
                    tenSecondsDuration - tenSecondsDelay === tenSecondsPassed
                )
                    return widget;
            });
            if (nextWidget) {
                nextWidget = { ...nextWidget, ...nextWidget.widgetInstance };
            }
        }
        if (nextWidget) {
            dispatch({ type: SET_WIDGET_PAST_EVENT, payload: nextWidget });
        }
    };

    useEffect(() => {
        async function checkVideo() {
            const activeVideo = await isUrlValid(props.event.live_stream_url);
            if (!activeVideo) {
                if (props.event.stream_type === 'webrtc_ivs') {
                    setCountDownTrigger(true);
                    setIsExternalStream(true);
                } else {
                    setIsExternalStream(false);
                }
            } else {
                setCountDownTrigger(false);
            }

            if (live && props.event.channel_status === 'STOPPED') {
                setIsStopped(true);
            } else if (props.event.channel_status) {
                setIsStopped(false);
            }
        }

        checkVideo();
    }, [
        props.event.channel_status,
        props.event.stream_status,
        props.event.start_stream_time,
        props.event.live_stream_url,
        props.event.stream_type,
        live
    ]);

    // useEffect(() => {
    //     async function checkVideo() {
    //         if (props.event.channel_status) {
    //             console.info(`Status ${props.event.channel_status}`);
    //             const url = props.event.live_stream_url;
    //             console.info(`URL to be checked ${url}`);
    //             let activeVideo = await isUrlValid(url);

    //             if (activeVideo) {
    //                 setCountDownTrigger(false);
    //                 return;
    //             }

    //             const interval = setInterval(async () => {
    //                 if (activeVideo) {
    //                     //setCountDownTrigger(false);
    //                     clearInterval(interval);
    //                 } else {
    //                     //setCountDownTrigger(true);
    //                     activeVideo = await isUrlValid(url);
    //                 }
    //             }, 5000);
    //             return () => {
    //                 clearInterval(interval);
    //             };
    //         }
    //     }

    //     checkVideo();
    // }, [props.event.channel_status]);
    const { _id: companyId } = props.company || {};
    const { id: eventId } = props.event || {};
    useEffect(() => {
        const fetchWatchCount = async () => {
            // Simulate initial action
            const success = await new Promise((resolve) => {
                setTimeout(() => {
                    if (companyId && eventId) {
                        props.getWatchCount({ companyId: companyId, eventId: eventId });
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, 1000);
            });

            if (success) {
                const action = () => {
                    props.getWatchCount({ companyId: companyId, eventId: eventId });
                };

                const intervalId = setInterval(action, 600000);

                // Cleanup interval on component unmount
                return () => clearInterval(intervalId);
            } else {
                setTimeout(fetchWatchCount, 1000);
            }
        };

        // Call the async function
        fetchWatchCount();

        if (eventOpen) {
            setIsEventOpened(true);
        } else {
            setIsEventOpened(false);
        }
        return () => {
            props.resetWatchCount();
        };
    }, [companyId, eventId]);

    useEffect(() => {
        if (props.event.stream_status === 'active') {
            setLiveLabel(true);
        }
    }, []);

    function onPlayerReady() {
        this.on('ontouchstart' in window ? 'touchstart' : 'click', (event) => {
            if ('ontouchstart' in window == true) {
                // touch
                if (
                    (location.pathname.includes(`/events/${props.event.slug}`) == false &&
                        event?.pageY <
                            event?.srcElement?.offsetHeight +
                                NAVBAR_HEIGHT -
                                CONTROL_BAR_VIDEO_PLAYER_HEIGHT) ||
                    event?.targetTouches[0]?.pageY <
                        event?.srcElement?.offsetHeight +
                            NAVBAR_HEIGHT -
                            CONTROL_BAR_VIDEO_PLAYER_HEIGHT
                ) {
                    history.push(`/events/${props.event.slug}`);
                }
            } else {
                // click
                if (
                    location.pathname.includes(`/events/${props.event.slug}`) == false &&
                    event?.target?.offsetHeight - CONTROL_BAR_VIDEO_PLAYER_HEIGHT > event.offsetY
                ) {
                    history.push(`/events/${props.event.slug}`);
                }
            }
        });
    }

    const notify = (message) => {
        if (message == 'Event already exists') {
            presentToast(message, 5000, 'success');
        } else {
            presentToast(message, 5000, 'success');
        }
    };

    const [sec, setSec] = useState();

    useEffect(() => {
        async function fetchData() {
            if (props.event) {
                const company = await sanity.Companies.GetCompanyById(
                    props.event.sports_team?._ref
                );
                if (company && company?.shown_seconds_from_video) {
                    setSec(company?.shown_seconds_from_video);
                } else {
                    const globalp = await sanity.GlobalParams.GetGlobalParams();
                    if (globalp) {
                        setSec(globalp.shown_seconds_from_video_global);
                    }
                }
            }
        }

        fetchData();
    }, [props.event]);

    const shareContent = () => {
        const url = `${window.location.origin}/share/event/${props.event.id}`;
        if (navigator.share) {
            navigator
                .share({
                    url: url
                })
                .then(() => {
                    console.log('Content shared successfully');
                })
                .catch((error) => {
                    console.error('Error sharing content:', error);
                });
        } else {
            navigator.clipboard.writeText(url);
            setShowDrawer(false);
            presentToast('Event link copied to clipboard.', '2000', 'success');
            console.warn('Web Share API is not supported in this browser');
        }
    };

    return (
        <Box key={props.event.title} className={styles.eventLive}>
            {countDownTrigger && (
                <CountdownPlayer
                    event={props.event}
                    stopped={isStopped}
                    opened={isEventOpened}
                    externalStream={isExternalStream}
                />
            )}

            <Box>
                {props.user.loaded && canSeeEvent(props.user, props.event) ? (
                    <div>
                        {!countDownTrigger && props.event.live_stream_url && (
                            <div style={isMobile ? { position: 'fixed', zIndex: '1000' } : {}}>
                                {props.isEventList &&
                                props.isAdmin &&
                                props.publishingEvents.length > 0 ? (
                                    <PublishingQueue
                                        publishingEvents={props.publishingEvents}
                                        handleCancel={props.handleCancel}
                                    />
                                ) : null}
                                <Player
                                    uniqueHash={uniqueHash}
                                    streamSource={props.event.live_stream_url}
                                    pastWidgets={eventOpen}
                                    videoTimeCallback={videoTimeCallback}
                                    viewLandscape={props.viewLandscape}
                                    onPlayerReady={onPlayerReady}
                                    muted={!eventOpen}
                                    sound={true}
                                    autoplay={!eventOpen}
                                    isLive={liveLabel}
                                    event={props.event}
                                    displaySeconds={sec}
                                    toggled={toggled}
                                    setTechnicalError={props.setTechnicalError}
                                    setIsPortrait={setIsPortrait}
                                />
                                {liveLabel && (
                                    <div className={styles.eventLiveInfo}>
                                        <span className={styles.liveTag}>Live</span>
                                        <span className={styles.liveViewers}>
                                            <img src={eye} alt={t('NumberOfViewers')} />
                                            {props.currentlyWatching}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        <Box display="flex" alignItems="center">
                            <EventSummary
                                event={props.event}
                                isLive={isLive}
                                isPremium={props.event.isPremium}
                                marginTop={isMobile}
                                countDownTrigger={countDownTrigger}
                                publishingEvents={props.publishingEvents}
                                isPortrait={isPortrait}
                            />
                            {(isPinned || isEventPage) && (
                                <div
                                    style={{
                                        display: 'flex',
                                        width: isMobile ? '30%' : '15%',
                                        flexDirection: 'row-reverse',
                                        alignItems: 'center',
                                        marginTop: isMobile
                                            ? countDownTrigger
                                                ? ''
                                                : props?.publishingEvents?.length > 0
                                                ? '37vh'
                                                : // : isPortrait
                                                  // ? '50vh'
                                                  '28vh'
                                            : ''
                                    }}>
                                    <IconButton
                                        sx={{ height: 'fit-content' }}
                                        aria-label="more"
                                        onClick={() => {
                                            if (isEventPage) {
                                                setShowDrawer(true);
                                            } else {
                                                setMenuOpen(true);
                                            }
                                        }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    {menuOpen && (
                                        <EventOptions
                                            event={props.event}
                                            isClicked={menuOpen}
                                            setIsClicked={setMenuOpen}
                                            notify={notify}
                                            setVideoDeleted={props.setVideoDeleted}
                                        />
                                    )}
                                    {props.displayVideoStats && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: '5%'
                                            }}>
                                            {totalViews ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                    <Typography>{totalViews}</Typography>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none">
                                                        <path
                                                            d="M5.46484 12.6432V3.35693L12.6515 8.00005L5.46484 12.6432Z"
                                                            fill="#888888"
                                                        />
                                                    </svg>
                                                </div>
                                            ) : null}

                                            {/*<div style={{ display: 'flex', alignItems: 'center' }}>*/}
                                            {/*    <Typography>123</Typography>*/}
                                            {/*    <svg*/}
                                            {/*        xmlns="http://www.w3.org/2000/svg"*/}
                                            {/*        width="16"*/}
                                            {/*        height="16"*/}
                                            {/*        viewBox="0 0 16 16"*/}
                                            {/*        fill="none">*/}
                                            {/*        <path*/}
                                            {/*            d="M4 12V4.93108C4 4.67115 4.09019 4.45098 4.27058 4.27059C4.45097 4.0902 4.67114 4 4.93107 4H11.0689C11.3289 4 11.549 4.0902 11.7294 4.27059C11.9098 4.45098 12 4.67115 12 4.93108V9.50079C12 9.76073 11.9098 9.98089 11.7294 10.1613C11.549 10.3417 11.3289 10.4319 11.0689 10.4319H5.56812L4 12Z"*/}
                                            {/*            fill="#888888"*/}
                                            {/*        />*/}
                                            {/*    </svg>*/}
                                            {/*</div>*/}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Box>
                        <Drawer
                            ModalProps={{
                                BackdropProps: {
                                    sx: { zIndex: '0' }
                                }
                            }}
                            anchor="bottom"
                            open={showDrawer}
                            onClose={() => setShowDrawer(false)}>
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    position: 'fixed',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    zIndex: '1300 !improtant',
                                    borderRadius: '8px 8px 0px 0px'
                                }}>
                                <div>
                                    <div
                                        className={stylesChat.button}
                                        onClick={() => shareContent()}
                                        style={{
                                            display: 'flex',
                                            padding: isMobile ? '5% 2%' : '2% 1%',
                                            alignItems: 'center'
                                        }}>
                                        <IosShareIcon
                                            sx={{ width: 26, height: 26, marginRight: '1%' }}
                                        />
                                        <Typography sx={{ fontFamily: 'Urbanist-Bold' }}>
                                            {t('ShareVideo')}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            margin: '0 auto',
                                            width: isMobile ? '92%' : '97%',
                                            borderBottom: '1px solid #0000001A'
                                        }}></div>
                                    <div
                                        className={stylesChat.button}
                                        onClick={() => {
                                            setShowDrawer(false);
                                            history.push(`/report/${props.event.id}`);
                                        }}
                                        style={{
                                            display: 'flex',
                                            padding: isMobile ? '5% 2%' : '2% 1%',
                                            alignItems: 'center'
                                        }}>
                                        <ReportGmailerrorredIcon
                                            sx={{ width: 26, height: 26, marginRight: '1%' }}
                                        />
                                        <Typography sx={{ fontFamily: 'Urbanist-Bold' }}>
                                            {t('ReportVideo')}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            margin: '0 auto',
                                            width: isMobile ? '92%' : '97%',
                                            borderBottom: '1px solid #0000001A'
                                        }}></div>
                                    <div
                                        style={{
                                            padding: isMobile ? '5% 2%' : '1.5% 1%'
                                        }}>
                                        <FormControlLabel
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                margin: '0'
                                            }}
                                            control={
                                                <Switch
                                                    onClick={() => {
                                                        setToggled(!toggled);
                                                    }}
                                                    checked={toggled}
                                                    color="primary"
                                                    className={classes.switch}
                                                />
                                            }
                                            label={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '50vw'
                                                    }}>
                                                    <VolumeUpOutlinedIcon
                                                        sx={{
                                                            width: 26,
                                                            height: 26,
                                                            marginRight: '1%'
                                                        }}
                                                    />
                                                    <Typography
                                                        sx={{ fontFamily: 'Urbanist-Bold' }}>
                                                        {t('Sound')}
                                                    </Typography>
                                                </div>
                                            }
                                            labelPlacement="start"
                                            value="schedule"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            margin: '0 auto',
                                            width: isMobile ? '92%' : '97%',
                                            borderBottom: '1px solid #0000001A'
                                        }}></div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            padding: isMobile ? '5% 2%' : '2% 1%',
                                            alignItems: 'center'
                                        }}>
                                        <PersonalVideoIcon sx={{ marginRight: '1%' }} />
                                        <Typography
                                            sx={{ fontFamily: 'Urbanist-Bold' }}
                                            fontWeight={'bold'}>
                                            {t('VideoQuality')}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            gap: isMobile ? '4px' : '20px',
                                            marginLeft: '1%',
                                            paddingBottom: isMobile ? '5%' : '2%'
                                        }}>
                                        <Button
                                            sx={{
                                                borderRadius: '40px',
                                                backgroundColor: 'black',
                                                color: 'white',
                                                textTransform: 'none',
                                                fontSize: '12px',
                                                height: '5vh',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '8px',
                                                '&:active, &:focus, &:hover': {
                                                    backgroundColor: 'black',
                                                    color: 'white'
                                                }
                                            }}>
                                            Automatic (720p)
                                        </Button>
                                        <Button
                                            sx={{
                                                borderRadius: '40px',
                                                backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                                color: 'black',
                                                textTransform: 'none',
                                                fontSize: '12px',
                                                height: '5vh',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '8px',
                                                '&:active, &:focus, &:hover': {
                                                    backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                                    color: 'black'
                                                }
                                            }}>
                                            High (1080p)
                                        </Button>
                                        <Button
                                            sx={{
                                                borderRadius: '40px',
                                                backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                                color: 'black',
                                                textTransform: 'none',
                                                fontSize: '12px',
                                                height: '5vh',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '8px',
                                                '&:active, &:focus, &:hover': {
                                                    backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                                    color: 'black'
                                                }
                                            }}>
                                            Medium (720p)
                                        </Button>
                                        <Button
                                            sx={{
                                                borderRadius: '40px',
                                                backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                                color: 'black',
                                                textTransform: 'none',
                                                fontSize: '12px',
                                                height: '5vh',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '8px',
                                                '&:active, &:focus, &:hover': {
                                                    backgroundColor: 'rgba(129, 129, 129, 0.10)',
                                                    color: 'black'
                                                }
                                            }}>
                                            Low (360p)
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Drawer>
                    </div>
                ) : premiumOnly ? (
                    <PremiumContentV2
                        event={props.event}
                        isLive={isLive}
                        isPreview={!isEventOpened}
                        user={props.user}
                    />
                ) : (
                    <PremiumContent
                        event={props.event}
                        isLive={isLive}
                        isPreview={!isEventOpened}
                        user={props.user}
                    />
                )}
            </Box>
        </Box>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EventInfo);
