import { IonContent, IonPage, useIonToast } from '@ionic/react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { goBack } from 'connected-react-router';
import { checkmarkCircleSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SET_COMPANY_DATA } from '../../../constants/actionTypes';
import sanity from '../../../sanity';
import { calculateBrightness } from '../../../utils';
import OnBack from '../../Auth/OnBack';
import PreviewScreen from '../Customize/PreviewScreen';
import styles from './customize.module.scss';
import './styles.css';

const mapStateToProps = (state) => {
    return {
        company: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    setCompanyData: (payload) => dispatch({ type: SET_COMPANY_DATA, payload })
});

const useStyles = makeStyles(() => ({
    textField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray'
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: 'gray'
        },
        color: 'white',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray'
        }
    },
    label: {
        '& .MuiInputLabel-root': {
            color: 'gray'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'gray'
        }
    }
}));

const ColorsPage = (props) => {
    const { t } = useTranslation();
    const [selectedColor, setSelectedColor] = useState(props.company.primaryColor);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [didColorChange, setDidColorChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [present] = useIonToast();
    const presentToast = (message) => {
        present({
            message: message,
            duration: 1500,
            position: 'top',
            icon: checkmarkCircleSharp,
            color: 'success'
        });
    };
    const premiumOnly = process.env.REACT_APP_PREMIUM_ONLY === 'true';

    const [textColor, setTextColor] = useState(() => {
        const brightness = calculateBrightness(selectedColor);
        const initalTextColor = brightness < 128 ? '#ffffff' : '#000000';
        return initalTextColor;
    });
    const classes = useStyles();
    let editedCompany = props.company;

    const saveChange = async (payload) => {
        setLoading(true);
        try {
            const cmp = payload;
            await sanity.Companies.EditCompany(props.company, cmp, props.company.creator);
            //setOpenSpinner(false);
            //setOpenSnack(true);
            //setStatusMessage('Company ' + company.name + ' is edited!');
            //setRequestSendMessage(false);
            props.setCompanyData(sanity.Companies.GetCompanyById(process.env.REACT_APP_COMPANY_ID));
            setTimeout(() => {
                history.goBack();
            }, 1600);
        } catch (e) {
            console.log(e.message);
        } finally {
            setLoading(false);
            presentToast(t('CompanyColorChanged'));
        }
    };

    const handleColorPickerOpen = () => {
        setShowColorPicker(true);
    };

    const handleColorPickerClose = () => {
        setShowColorPicker(false);
    };

    const handlePreviewOpen = () => {
        setShowPreview(true);
    };

    const handlePreviewClose = () => {
        setShowPreview(false);
    };

    useEffect(() => {
        const brightness = calculateBrightness(selectedColor);
        const newTextColor = brightness < 128 ? '#ffffff' : '#000000';
        setTextColor(newTextColor);
        editedCompany.primaryColor = selectedColor;
        editedCompany.backgroundCompanyColor = selectedColor;
    }, [selectedColor, editedCompany]);

    return (
        <IonPage>
            <OnBack
                defaultHref={premiumOnly ? '/profile/branding' : '/profile/customize'}
                title={'Colors'}
                onClick={() => saveChange(editedCompany)}
                showButtonOrLoader={loading ? 'loader' : 'button'}
                buttonDisabled={!didColorChange}
                buttonText={t('Done')}
            />
            <IonContent>
                <div className={styles.profileWrapper} style={{ minHeight: '100%' }}>
                    <div className={styles.header}></div>
                    <div style={{ padding: '6vh 2vw 0vh 2vw' }}>
                        <Typography className={styles.itemSubtitle} style={{ textAlign: 'center' }}>
                            {t('ColorsTip')}
                        </Typography>
                        <div
                            style={{
                                padding: '8vh 0 3vh 0'
                            }}>
                            <Typography style={{ marginBottom: '2vh' }}>
                                {t('CustomColor')}
                            </Typography>
                            <TextField
                                fullWidth
                                label={t('PrimaryColor')}
                                placeholder={props.company.primaryColor}
                                value={selectedColor}
                                onChange={(e) => {
                                    setSelectedColor(e.target.value);
                                    setDidColorChange(true);
                                }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    style: {
                                        height: '5vh'
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            sx={{
                                                ' &:hover': {
                                                    cursor: 'pointer'
                                                }
                                            }}
                                            position="end">
                                            <AddIcon
                                                onClick={handleColorPickerOpen}
                                                style={{
                                                    fontSize: '40px',
                                                    color: textColor,
                                                    backgroundColor: selectedColor,
                                                    borderRadius: '4px'
                                                }}
                                            />
                                        </InputAdornment>
                                    )
                                }}></TextField>
                        </div>
                        <Button
                            onClick={() => {
                                handlePreviewOpen();
                            }}
                            sx={{
                                backgroundColor: selectedColor,
                                '&:active, &:focus, &:hover': {
                                    backgroundColor: selectedColor
                                },
                                marginTop: '3vh',
                                color: textColor,
                                width: '100%',
                                height: '10vh',
                                display: 'flex',
                                textTransform: 'none',
                                border: '1px solid rgba(0, 0, 0, 0.23)'
                            }}>
                            Preview
                        </Button>
                    </div>
                    <Dialog
                        PaperProps={{
                            style: {
                                background: 'black'
                            }
                        }}
                        open={showColorPicker}
                        onClose={handleColorPickerClose}
                        fullScreen>
                        <DialogTitle>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography textAlign={'center'} sx={{ color: 'white ', flex: 1 }}>
                                    Choose a color
                                </Typography>
                                <IconButton
                                    sx={{ color: 'white', marginLeft: '-40px' }}
                                    onClick={handleColorPickerClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <section className="custom-layout">
                                    <HexColorPicker
                                        color={selectedColor}
                                        onChange={(e) => {
                                            setSelectedColor(e);
                                            setDidColorChange(true);
                                        }}
                                    />
                                </section>
                                <div style={{ marginTop: '5vh' }}>
                                    <TextField
                                        fullWidth
                                        label={'Primary color'}
                                        placeholder={t('Default')}
                                        InputLabelProps={{ shrink: true }}
                                        value={selectedColor}
                                        inputProps={{
                                            style: {
                                                height: '5vh',
                                                color: 'white'
                                            }
                                        }}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                        className={`${classes.textField} ${classes.label}`}
                                    />
                                    <Button
                                        className={styles.previewButton}
                                        sx={{
                                            backgroundColor: 'white',
                                            '&:active, &:focus, &:hover': {
                                                backgroundColor: 'white'
                                            },
                                            marginTop: '3vh'
                                        }}
                                        onClick={() => {
                                            console.log(selectedColor);

                                            handleColorPickerClose();
                                        }}>
                                        Done
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullScreen
                        PaperProps={{
                            style: {
                                background: 'black'
                            }
                        }}
                        open={showPreview}>
                        <DialogTitle>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography textAlign={'center'} sx={{ color: 'white ', flex: 1 }}>
                                    {t('Preview')}
                                </Typography>
                                <IconButton
                                    sx={{ color: 'white', marginLeft: '-40px' }}
                                    onClick={handlePreviewClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                backgroundColor: 'black'
                            }}>
                            <div>
                                <PreviewScreen props={editedCompany} />
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorsPage);
