import { IonContent, IonPage, useIonToast } from '@ionic/react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import IVSBroadcastClient from 'amazon-ivs-web-broadcast';
import { checkmarkCircleSharp, closeCircleSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import copy from '../../assets/images/copy.svg';
import {
    CREATE_EVENT,
    SET_IVS_STREAM_CONFIG,
    SET_STREAMING_CLIENT,
    SPINNER_VISIBLE
} from '../../constants/actionTypes';

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        width: '100%',
        display: 'block',
        background: 'linear-gradient(#291d42, black, black)',
        color: 'white'
    },
    headerWrapper: {
        display: 'block',
        textAlign: 'center'
    },
    flexContainer: {
        margin: '5vh 2vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid white',
        borderRadius: '16px',
        padding: '5%'
    },
    linebreak: {
        margin: '3vh 0',
        width: '80vw',
        border: '1px solid white'
    },
    closeButton: {
        position: 'absolute !important',
        top: 5,
        right: 5
    },
    cancelButton: {
        textTransform: 'none !important',
        width: '80vw',
        height: '8vh',
        backgroundColor: 'white !important',
        color: 'black'
    },
    marginY: {
        margin: '1vh 0 !important'
    },
    contentContainer: {
        display: 'block',
        height: '100%',
        color: 'white',
        padding: 20
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 8,
        padding: 8,
        borderRadius: 8,
        color: 'white'
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray'
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: 'gray'
        },
        color: 'white',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        }
    },
    label: {
        '& .MuiInputLabel-root': {
            color: 'gray'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'white'
        }
    },
    switch: {
        '& .MuiSwitch-track': {
            backgroundColor: 'lightgray'
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: 'white'
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#32E7B2'
        }
    },
    datepicker: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        },
        '& .MuiInputLabel-root': {
            color: 'white'
        },
        '& .MuiInputBase-input': {
            color: 'white'
        }
    }
}));

const streamConfig = IVSBroadcastClient.STANDARD_LANDSCAPE;

const API_ENDPOINTS = {
    createStream: `${process.env.REACT_APP_API_URL}/ivs/createStream`,
    prepareStreamEvent: `${process.env.REACT_APP_API_URL}/ivs/prepare-stream`
};

const mapStateToProps = (state) => {
    return {
        ...state.liveStreaming,
        user: state.user.currentUser,
        convertVideoJobIds: state.schedule.convertVideoJobIds,
        conversionStatus: state.schedule.conversionStatus,
        company: state.common.company,
        spinner: state.common.spinner
    };
};
const mapDispatchToProps = (dispatch) => ({
    setIvsConfig: (ivsConfig) =>
        dispatch({
            type: SET_IVS_STREAM_CONFIG,
            payload: ivsConfig
        }),
    setStreamingClient: (streamingClient) =>
        dispatch({
            type: SET_STREAMING_CLIENT,
            payload: streamingClient
        }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    createEventSanity: (eventPayload) => dispatch({ type: CREATE_EVENT, payload: eventPayload })
});

const StreamDetails = (props) => {
    const [streamDetails1, setStreamDetails1] = useState('');
    const [streamDetails2, setStreamDetails2] = useState('');
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [present] = useIonToast();
    const presentToast = (message, icon, color) => {
        present({
            message: message,
            duration: 1500,
            position: 'top',
            icon: icon,
            color: color
        });
    };

    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const [countdown, setCountdown] = useState(600);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const storedTitle = localStorage.getItem('videoTitle');
        const storedDescription = localStorage.getItem('videoDescription');
        const storedTags = localStorage.getItem('tagsLive');
        if (storedTitle) {
            setTitle(storedTitle);
        }
        if (storedDescription) {
            setDescription(storedDescription);
        }
        if (storedTags.length > 0) {
            setTags(JSON.parse(storedTags));
        }
    }, []);

    useEffect(() => {
        if (title !== '') {
            loadStream();
        }
    }, [title]);

    const initStreamConfig = async () => {
        try {
            const response = await fetch(API_ENDPOINTS.createStream);
            const data = await response.json();

            if (data.success) {
                if (data.data.channel.ingestEndpoint) {
                    props.setStreamingClient(
                        IVSBroadcastClient.create({
                            streamConfig,
                            ingestEndpoint: `rtmps://${data.data.channel.ingestEndpoint}:443/app/`
                        })
                    );
                    props.createEventSanity({
                        title: title,
                        description: description,
                        slug: {
                            type: 'slug',
                            current: props.videoTitle + Math.ceil(Math.random() * 1000000)
                        },
                        startDate: new Date(),
                        event_type: 'video_event',
                        stream_type: 'webrtc_ivs',
                        endpoint: data.data.channel.playbackUrl,
                        liveStreamUrl: data.data.channel.playbackUrl,
                        stream_arn: data.data.channel.arn,
                        thumbnail: props.videoThumbnail,
                        streamStatus: 'upcoming',
                        user_id: props.user.data.sub,
                        tags: tags,
                        resource_creation_time: 0,
                        sports_team: {
                            _ref: process.env.REACT_APP_COMPANY_ID,
                            _type: 'reference'
                        }
                    });
                }

                props.setIvsConfig(data);
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loadStream = async () => {
        setLoading(true);
        try {
            await initStreamConfig();
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setStreamDetails1(props.config?.data.channel.ingestEndpoint);
        setStreamDetails2(props.config?.data.streamKey.value);
    }, [props.config]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    const handleCopyText = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            presentToast('Details copied to clipboard', checkmarkCircleSharp, 'success');
        } catch (error) {
            presentToast('Error copying text to clipboard', closeCircleSharp, 'danger');
        }
    };

    const handleCloseClick = () => {
        history.push('/events');
    };

    return (
        <IonPage>
            <IonContent>
                <div className={classes.root}>
                    {!loading ? (
                        <>
                            <div className={classes.headerWrapper}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                        variant="h6"
                                        textAlign={'center'}
                                        sx={{ color: 'white ', flex: 1 }}>
                                        {t('StreamDetails')}
                                    </Typography>
                                    <IconButton
                                        sx={{ color: 'white', marginLeft: '-40px' }}
                                        onClick={handleCloseClick}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={classes.flexContainer}>
                                <Button
                                    className={classes.marginY}
                                    sx={{ borderRadius: '48px' }}
                                    variant="contained">
                                    {t('StreamReady')}
                                </Button>
                                <Typography className={classes.marginY}>
                                    {t('CredentialsExpiring')}
                                </Typography>
                                <Typography>{formatTime(countdown)}</Typography>
                                <div className={classes.linebreak}></div>
                                <Button
                                    onClick={() => history.push('/create-video')}
                                    className={classes.cancelButton}
                                    variant="contained">
                                    {t('CancelStream')}
                                </Button>
                            </div>
                            <div style={{ padding: '0 2vw', marginBottom: '1rem' }}>
                                <Typography style={{ marginBottom: '1rem' }} color={'white'}>
                                    {t('Details')}
                                </Typography>
                                <TextField
                                    fullWidth
                                    value={
                                        streamDetails1 ? `rtmps://${streamDetails1}:443/app/` : ''
                                    }
                                    id="myTextField"
                                    InputProps={{
                                        readOnly: true,
                                        style: {
                                            color: 'gray',
                                            backgroundColor: 'rgba(250, 250, 250, 0.20)'
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        handleCopyText(
                                                            `rtmps://${streamDetails1}:443/app/`
                                                        )
                                                    }>
                                                    <img src={copy} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{ marginBottom: '1rem' }}
                                />
                                <TextField
                                    fullWidth
                                    value={streamDetails2 ? streamDetails2 : ''}
                                    id="myTextField"
                                    InputProps={{
                                        readOnly: true,
                                        style: {
                                            color: 'gray',
                                            backgroundColor: 'rgba(250, 250, 250, 0.20)'
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleCopyText(streamDetails2)}>
                                                    <img src={copy} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <div
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)'
                            }}>
                            <CircularProgress
                                sx={{
                                    width: '5em !important',
                                    height: '5em !important'
                                }}
                            />
                        </div>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamDetails);
