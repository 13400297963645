import ApiResponse from './core/entitites/apiResponse.entity';
import { toUnitAmount } from './utils/unitAmountHelper';
import toast from 'react-hot-toast';

const url = process.env.REACT_APP_API_URL;

async function fetchWithRetry(url, options, retries = 3) {
    try {
        const response = await fetch(url, options);
        // if (response.status >= 500) {
        //     if (retries > 0) {
        //         return fetchWithRetry(url, options, retries - 1);
        //     } else {
        //         toast.error(`Max retries reached for ${url}`, {
        //             duration: 1500,
        //             position: 'top-center'
        //         });
        //     }
        // }
        return response;
    } catch (error) {
        // if (retries > 0) {
        //     return fetchWithRetry(url, options, retries - 1);
        // } else {
        //     toast.error(error.message, { duration: 1500, position: 'top-center' });
        // }
    }
}

const Image = {
    Upload: async (requestOptions) => {
        return fetchWithRetry(`${url}/user`, requestOptions).then((response) => response.json());
    }
};

const Event = {
    UploadThumbnail: async (requestOptions) => {
        return fetchWithRetry(`${url}/event/thumbnail`, requestOptions).then((response) =>
            response.json()
        );
    },
    UploadVideo: async (requestOptions) => {
        return fetchWithRetry(`${url}/video`, requestOptions).then((response) => response.json());
    },
    UploadVideoMpInit: async (requestOptions) => {
        return fetchWithRetry(`${url}/videoMpInit`, requestOptions).then((response) =>
            response.json()
        );
    },
    UploadVideoMpPresignedUrl: async (requestOptions) => {
        return fetchWithRetry(`${url}/videoMpPresignedUrl`, requestOptions).then((response) =>
            response.json()
        );
    },
    UploadVideoMpFins: async (requestOptions) => {
        return fetchWithRetry(`${url}/videoMpFins`, requestOptions).then((response) => response);
    },
    DeleteEvent: async (id) => {
        return fetchWithRetry(`${url}/event/${id}`, {
            method: 'DELETE',
            mode: 'cors'
        })
            .then((response) => response)
            .catch((e) => e);
    },
    UpdateEvent: async (requestOptions) => {
        return fetchWithRetry(`${url}/event/update`, requestOptions)
            .then((response) => response.json())
            .catch((e) => e);
    },
    EventShareMail: async (eventId) => {
        return fetchWithRetry(`${url}/event/${eventId}/mail`, {
            method: 'POST',
            mode: 'cors'
        })
            .then((response) => response.json())
            .catch((e) => e);
    }
};

const Invoice = {
    getAllInvoices: async (subscription_id, accountId) => {
        let stripeHeader;
        if (accountId) {
            stripeHeader = { 'Stripe-Account': accountId };
        }
        let invoiceList = await fetchWithRetry(
            `${url}/payments/invoices?subscription_id=${subscription_id}`,
            {
                headers: { 'Content-Type': 'application/json', ...stripeHeader }
            }
        );
        return invoiceList.json();
    },

    getInvoiceById: async (id, accountId) => {
        let stripeHeader;
        if (accountId) {
            stripeHeader = { 'Stripe-Account': accountId };
        }
        let invoice = await fetchWithRetry(`${url}/payments/invoices?id=${id}`, {
            headers: { 'Content-Type': 'application/json', ...stripeHeader }
        });
        return invoice.json();
    },

    getUpcomingInvoice: async (subscription_id, accountId) => {
        let stripeHeader;
        if (accountId) {
            stripeHeader = { 'Stripe-Account': accountId };
        }
        let invoiceUpcoming = await fetchWithRetry(
            `${url}/payments/invoices/upcoming?subscription_id=${subscription_id}`,
            {
                headers: { 'Content-Type': 'application/json', ...stripeHeader }
            }
        );
        return invoiceUpcoming.json();
    },

    getInvoicePdf: async (purl) => {
        let pdf = await fetchWithRetry(`${url}/payments/invoices/pdf?url=${purl}`)
            .then((response) => response.text())
            .catch((error) => console.log(error));

        return pdf;
    }
};

const Prices = {
    Get: async (priceId, accountId) => {
        const response = await fetchWithRetry(`${url}/payments/prices/${priceId}`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', 'Stripe-Account': accountId }
        });
        return response.json();
    },
    List: async () => {
        const response = await fetchWithRetry(`${url}/payments/prices`);
        return response.json();
    },
    Latest: async (accountId) => {
        const response = await fetchWithRetry(`${url}/payments/prices/latest`, {
            headers: { 'Content-Type': 'application/json', 'Stripe-Account': accountId }
        });
        return response.json();
    },
    Create: async (productId, accountId, amount, currency = 'EUR') => {
        const response = await fetchWithRetry(`${url}/payments/prices/${productId}`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ unit_amount: toUnitAmount(amount, currency), currency }), //, lookup_key: "latest" , transfer_lookup_key: true
            headers: { 'Content-Type': 'application/json', 'Stripe-Account': accountId }
        });
        return response.json();
    },
    Search: async (productId, accountId) => {
        let query = {};
        if (productId) {
            query = { 'q!product': productId };
        }
        const response = await fetchWithRetry(`${url}/payments/prices/search`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(query), //  , "q!lookup_key" : "latest"
            headers: { 'Content-Type': 'application/json', 'Stripe-Account': accountId }
        });
        return response.json();
    }
};

const Products = {
    getAccountProducts: async () => {
        let products = await fetchWithRetry(`${url}/payments/products`)
            .then((response) => response.text())
            .catch((error) => console.log(error));

        return products;
    },
    List: async (accountId) => {
        const response = await fetchWithRetry(`${url}/payments/products`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', 'Stripe-Account': accountId }
        });
        return response.json();
    },
    Search: async (accountId, setUpPrice) => {
        const response = await fetchWithRetry(`${url}/payments/products/search`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ "q!metadata['setup-price']": `${setUpPrice}` }),
            headers: { 'Content-Type': 'application/json', 'Stripe-Account': accountId }
        });
        return response.json();
    }
};

const Subscription = {
    Update: async (subscriptionId, accountId) => {
        let stripeHeader;
        if (accountId) {
            stripeHeader = { 'Stripe-Account': accountId };
        }
        const response = await fetchWithRetry(
            `${url}/payments/subscriptions/${subscriptionId}/update`,
            {
                method: 'PUT',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json', ...stripeHeader }
            }
        );
        return response.json();
    }
};

const Checkout = {
    Create: async (line_items, callbackUrl, email, accountId, metadata) => {
        let stripeHeader;
        if (accountId) {
            stripeHeader = { 'Stripe-Account': accountId };
        }
        const response = await fetchWithRetry(`${url}/payments/checkout`, {
            method: 'POST',
            body: JSON.stringify({ line_items, callbackUrl, email, metadata }),
            headers: { 'Content-Type': 'application/json', ...stripeHeader }
        });
        return response.json();
    }
};

const Account = {
    Delete: async (userId, userPoolId, companyId) => {
        return fetchWithRetry(`${url}/user/${userId}/delete`, {
            method: 'DELETE',
            body: JSON.stringify({ userPoolId, companyId }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((response) => new ApiResponse(response))
            .catch((e) => new ApiResponse(e));
    },
    CancelDeletion: async (userId, userPoolId) => {
        return fetchWithRetry(`${url}/user/cancelDeletion/${userId}`, {
            method: 'PATCH',
            body: JSON.stringify({ userPoolId }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((r) => new ApiResponse(r))
            .catch((e) => new ApiResponse(e));
    }
};

const StripeAccount = {
    createAccountStripe: async (requestOptions) => {
        return fetchWithRetry(`${url}/payments/accounts`, requestOptions).then((response) =>
            response.json()
        );
    },

    getAccountByCompany: async (companyId) => {
        return await fetchWithRetry(`${url}/payments/accounts/${companyId}`).then((response) =>
            response.json()
        );
    },
    getAccountBalance: async (accountId) => {
        return await fetchWithRetry(`${url}/payments/accounts/${accountId}/balance`).then(
            (response) => response.json()
        );
    },
    getBalanceTransactions: async (accountId, limit) => {
        return await fetchWithRetry(
            `${url}/payments/accounts/${accountId}/transactions?limit=${limit}`
        ).then((response) => response.json());
    },
    getSubscriptionStatus: async (email, companyId, accountId, sessionId) => {
        let stripeHeader;
        if (accountId) {
            stripeHeader = { 'Stripe-Account': accountId };
        }
        const response = await fetchWithRetry(
            `${url}/payments/customers/premium?customer_email=${email}&sessionId=${sessionId}&companyId=${companyId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...stripeHeader
                },
                credentials: 'include'
            }
        );
        return response.json();
    }
};

const Chat = {
    Auth: async (data) => {
        const response = await fetchWithRetry(`${url}/chat/auth`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        });
        return response.json();
    },
    Event: async (data) => {
        const response = await fetchWithRetry(`${url}/chat`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        });
        return response.json();
    },
    List: async (roomArn, startTime, endTime) => {
        const response = await fetchWithRetry(`${url}/chat/messages`, {
            method: 'POST',
            body: JSON.stringify({ arn: roomArn, startTime, endTime }),
            headers: { 'Content-Type': 'application/json' }
        });
        return response.json();
    },
    BlockedUser: async (userId) => {
        const response = await fetchWithRetry(`${url}/chat/block/${userId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
        return response.json();
    }
};

const Company = {
    CheckCompanyName: async (companyName) => {
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let status = await fetchWithRetry(
            `${url}/company-data/check-company-name?companyName=${companyName}`,
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                return result;
            })
            .catch((error) => console.log(error));

        return status;
    },

    WelcomeSession: async (sessionId) => {
        let response = await fetchWithRetry(`${url}/company/session/${sessionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    },

    CreateCompanyDocument: async (requestOptions) => {
        let response = await fetchWithRetry(
            `${url}/dashboard/create-company-doc`,
            requestOptions
        ).then((response) => response.json());
        return response;
    },

    EditCompanyDocument: async (requestOptions) => {
        let response = await fetchWithRetry(
            `${url}/dashboard/edit-company-doc`,
            requestOptions
        ).then((response) => response.json());
        return response;
    },

    SetDemoPlanToCompany: async (requestOptions) => {
        let response = await fetchWithRetry(`${url}/plan/demo`, requestOptions).then((response) =>
            response.json()
        );
        return response;
    },

    /**
     * Returns streaming stats for provided companyId
     * @param data - The data object that you want to convert to URI parameters.
     * @returns A object.
     */
    GetCompanyStreamingStats: async (companyId) => {
        if (!companyId) {
            console.error('Company ID is not provided.');
            return;
        }

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();

        return await fetchWithRetry(
            `${url}/stats/statistic?companyId=${companyId}&month=${currentMonth}&year=${currentYear}`
        )
            .then((r) => r.json())
            .then((r) => {
                if (r?.Message) throw new Error(r.Message);
                return r;
            })
            .catch((e) => {
                throw new Error(e);
            });
    },

    /**
     * Gets status of custom doamin request validation
     * @param data - The data object that you want to convert to URI parameters.
     * @returns A object.
     */
    GetCustomDomainStatus: async (
        data = {
            email: '',
            companyName: ''
        }
    ) => {
        if (!data.companyName || !data.email) {
            console.error('One of the required params was not found.');
            return;
        }

        return await fetchWithRetry(
            `${url}/company-data/check-domain-progress?${dataToUriParams(data)}`
        )
            .then((r) => r.json())
            .then((r) => {
                if (r?.Message) throw new Error(r.Message);
                return r;
            })
            .catch((e) => {
                throw new Error(e);
            });
    },

    /**
     * Creates custom domain request.
     * @param data - The data object that you want to convert to URI parameters.
     * @returns Response from api.
     */
    CreateCustomDomainRequest: async (
        data = {
            companyId: '',
            email: '',
            subDomain: '',
            requestedDomain: ''
        }
    ) => {
        if (!data.companyId || !data.email || !data.subDomain || !data.requestedDomain) {
            console.error('One of the required params was not found.');
            return;
        }

        return await fetchWithRetry(`${url}/company-data/add-domain?${dataToUriParams(data)}`)
            .then((r) => r.json())
            .then((r) => {
                if (r?.Message) throw new Error(r.Message);
                return r;
            })
            .catch((e) => {
                throw new Error(e);
            });
    },

    PhotoInsert: async (data) => {
        const bodyData = data.data;
        const bodyPhoto = {
            lastModified: data.photo.lastModified,
            lastModifiedDate: data.photo.lastModifiedDate,
            name: data.photo.name,
            size: data.photo.size,
            type: data.photo.type,
            webkitRelativePath: data.photo.webkitRelativePath
        };
        const payload = JSON.stringify({
            photo: bodyPhoto,
            data: bodyData
        });
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: payload
        };
        let response = await fetchWithRetry(`${url}/dashboard/assets`, requestOptions).then(
            (response) => response.json()
        );
        return response;
    }
};

export default {
    Chat,
    Image,
    Event,
    Company,
    Invoice,
    Account,
    Prices,
    Products,
    Checkout,
    StripeAccount,
    Subscription
};

/**
 * It takes an object and returns a string of the object's key/value pairs in URI encoded format
 * @param data - The data object that you want to convert to URI parameters.
 * @returns A string of key value pairs separated by an ampersand.
 */
const dataToUriParams = (data) => {
    return Object.keys(data)
        .map((key) => {
            return `${key}=${encodeURIComponent(data[key])}&`;
        })
        .join('');
};
